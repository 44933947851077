
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';
import useFormErrors from '@/utils/validate';
import { } from '@/services/api';

import { useUpdateSegpay, useSegpay, useCreateSegpay } from '@/composables/api';
import { PartialSegpay, paymentType, result, status } from '@/interfaces/Segpay';

const DEFAULT_FORM_VALUES: PartialSegpay = {
  id: null,
  startDate: null,
  endDate: null,
  paymentType: null,
  result: null,
  transactionAmount: null,
  transactionFee: null,
  settlementFee: null,
  reserveHeld: null,
  totalFee: null,
  totalNet: null,
  totalPayoutAmount: null,
  status: null,
  payoutDate: null
};

const rules: Rules = {
  startDate: [
    {
      required: true
    }
  ],
  endDate: [
    {
      required: true
    }
  ],
  paymentType: [
    {
      required: true
    }
  ],
  result: [
    {
      required: true
    }
  ],
  transactionAmount: [
    {
      required: true
    }
  ],
  transactionFee: [
    {
      required: true
    }
  ],
  settlementFee: [
    {
      required: true
    }
  ],
  totalNet: [
    {
      required: true
    }
  ],
  totalPayoutAmount: [
    {
      required: true
    }
  ],
  status: [
    {
      required: true
    }
  ],
  payoutDate: [
    {
      required: true
    }
  ]
};

const PAYMENTTYPE = [
  {
    value: paymentType.CREDIT_CARD,
    label: 'Credit Card'
  }
];

const RESULT = [
  {
    value: result.AUTHORIZED,
    label: 'Authorized'
  }, {
    value: result.DECLINED,
    label: 'Declined'
  }
];

const STATUS = [
  {
    value: status.PAID,
    label: 'paid'
  }, {
    value: status.UNPAID,
    label: 'unpaid'
  }
];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const id = useRoute().params.id as string;
    const router = useRouter();
    const paymentTypeOptions = ref(PAYMENTTYPE);
    const resultOptions = ref(RESULT);
    const statusOptions = ref(STATUS);
    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateSegpay();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateSegpay();
    const { formErrors, bindFormItemError } = useFormErrors();

    const { data } = useSegpay({ id }, { enabled: isEdit.value });
    watch(data, ({ data }) => {
      formValues.value = data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { id, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.replace({
                      name: 'list-segpay'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );
              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.replace({
                    name: 'list-segpay'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      paymentTypeOptions,
      resultOptions,
      statusOptions,
      bindFormItemError
    };
  }
});
