import { BaseEntity } from './BaseEntity';

export enum paymentType {
  CREDIT_CARD = 'credit_card',
}

export enum result {
  AUTHORIZED = 'authorized',
  DECLINED = 'declined'
}

export enum status {
  PAID = 'paid',
  UNPAID = 'unpaid'
}

export interface Segpay extends BaseEntity {
  startDate: String
  endDate: String
  paymentType: paymentType
  result: String
  transactionAmount: Number
  transactionFee: Number
  settlementFee: Number
  reserveHeld: Number
  totalFee: Number
  totalNet: Number
  totalPayoutAmount: Number
  status: String
  payoutDate: String
}

export type PartialSegpay = Partial<Segpay>;
